/*
Below styles make the first (the checkbox selection for the line) and second (PO line) columns as a sticky column.
This is done using a standard css file and not in the PriorYearAccrualPage.styles.ts file. It seems that the DetailsList
and/or the Sticky component sets the position css property. I found that doing this by using native css works.
*/

/* Style for grid header checkbox (on the top left of the grid). */
#gridHeaderCheckbox {
    position: relative;
    top: 24px;
    height: 0px;
    left: 0px;
    padding: 12px;
    background-color: white;
    width: 40px;
    z-index: 3;
}

/* If screen is at least 600px wide, then use the below styles to make the first and second columns sticky. */
/* This is not done for smaller screens because when zoomed in close or on a smaller screen, the first two columns */
/* could take up the entire width of the screen, and block any view of columns to the right. */
@media screen and (min-width: 600px) {
    #gridHeaderCheckbox {
        position: sticky; /* Change from relative to sticky when screen is larger. */
    }
    #priorYearAccrualDetailsList div[class^="stickyAbove"] {
        z-index: 10; /* Make the sticky header row above the sticky columns. */
    }
    /* Header column 1 cell - the checkbox column. */
    #priorYearAccrualDetailsList .ms-DetailsHeader-cell:nth-child(1) {
        position: sticky!important;
        inset: 0;
        background-color: white;
        z-index: 2;
    }
    /* Data column 1 cell - the checkbox column. */
    #priorYearAccrualDetailsList .ms-DetailsRow-cell:nth-child(1) {
        position: sticky!important;
        inset: 0;
        background-color: white;
        z-index: 1;
    }
    /* Header column 2 cell - the PO/line column. */
    #priorYearAccrualDetailsList .ms-DetailsHeader-cell:nth-child(2) {
        position: sticky!important;
        left: 62px!important;
        inset: 0;
        background-color: white;
        z-index: 2;
    }
    /* Data column 2 cell - the PO/line column. */
    #priorYearAccrualDetailsList .ms-DetailsRow-cell:nth-child(2) {
        position: sticky!important;
        left: 62px!important;
        inset: 0;
        background-color: white;
        z-index: 1;
    }
}

/* Add a border to the right side of the cells. */
#priorYearAccrualDetailsList .ms-DetailsRow-cell {
    border-right: 1px solid rgb(243, 242, 241)!important;
}
