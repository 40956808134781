/*
Style overrides for the DetailsList control.
Using this separate css file rather than putting the below style in the CustomDetailsList.styles.ts because it seems that
when the DetailsList re-renders or changes from sticky to non-sticky (when using a <Sticky> component in the header when
implementing onRenderDetailsHeader), using the below style with the CSS-in-js approach seems to get lost. Doing it this way
with a css file keeps the style around on the page permanently.
*/

/* This makes the width of the cell be 100% so that when the tooltip is used it will appear above the center of the column. */
.ms-DetailsHeader-cellName {
    width: 100%!important
}

/* This removes the padding top, which is normally 16px. This will apply both to when a sticky header is used or not. */
.ms-DetailsHeader {
    padding-top: 0!important
}
